$font-family:'Roboto', sans-serif;
$font-weight-headers: 300;
$font-weight-base: 400;
$font-size-base: 1em;
$font-size-h1: 1.8em;
$font-size-h2: 1.4em;
$font-size-h3: 1.1em;
$font-size-small: 0.9em;
$font-color-base: rgba(#000,0.87);
$font-color-light: rgba(#000,0.54);

body {
  background: #ffffff;
  color: $font-color-base;
  font-family: $font-family;
  line-height: 1.5;
  font-weight: $font-weight-base;

  @media screen and (min-width: 600px) {
    padding-top: 150px;
  }

  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

h1, h2, h3 {
  font-weight: $font-weight-headers;
}

h1 {
  font-size: $font-size-h1;
  text-transform: uppercase;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
  font-weight: 500;
}

a {
  text-decoration: none;
  color: $font-color-base;
  border-bottom: 1px solid;

  &:hover {
    color: $font-color-light;
  }
}

.projects {

  @media screen and (min-width: 600px) {
    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.social {

  ul {
    font-size: $font-size-small;
    font-weight: 100;

    @media screen and (min-width: 600px) {
      display: inline;
      padding-left: 0;
      li {
        display: inline-block;
        list-style: none;
        &:not(:last-child):after { content: "|"; padding: 0 .5em; }
      }
    }
  }

  a {
    border: none;
  }
}

footer {
  font-size: $font-size-small;
}
